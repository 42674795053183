import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue } from "bootstrap-vue";
import VueI18n from "vue-i18n";
import VueMeta from "vue-meta";
import store from "./store";
import "leaflet/dist/leaflet.css";

Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(VueMeta);

Vue.config.productionTip = false;

const messages = {
  en: require("@/locales/en.json"),
  fi: require("@/locales/fi.json"),
};

export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0], // set locale,
  fallbackLocale: "en",
  messages,
});

export const app = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
