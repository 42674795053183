import hsl from "./axiosConfig";

export const getReportChart = async (payload, locale = "fi") => {
  try {
    const response = await hsl.post(`/reports/report-chart`, null, {
      params: {
        ...payload,
        language: locale,
      },
    });
    return response;
  } catch (err) {
    throw new Error(
      `Unable to get chart: ${payload.question_type} (${locale})`,
      err
    );
  }
};

export const getCommuterTypes = async (locale = "fi") => {
  try {
    const response = await hsl.get(`/reports/commuter-types/${locale}`);
    return response.data;
  } catch (err) {
    throw new Error(`Unable to get commuter types (${locale})`, err);
  }
};
