<template>
  <div class="overlay">
    <Loading />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "../components/Loading.vue";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions(["oidcSignInCallback"]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
        this.$router.push("/"); // Handle errors any way you want
      });
  },
  components: { Loading },
};
</script>
<style scoped>
.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
