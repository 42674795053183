import axios from "axios";

const hsl = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

// https://api.digitransit.fi/geocoding/v1/autocomplete?text=havukkatie%2021&layers=address
export const digitransit = axios.create({
  baseURL: "https://api.digitransit.fi",
});

export default hsl;
