<template>
  <div
    id="app"
    style="
      position: absolute;
      top: 0;
      width: 100%;
      min-width: 100%;
      overflow-x: hidden;
    "
  >
    <Navigation v-if="oidcIsAuthenticated" />
    <div class="app-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: { Navigation },
  metaInfo() {
    return {
      titleTemplate: (titleChunk) =>
        titleChunk
          ? `${titleChunk} | HSL ${this.$t("common.commute_counter")}`
          : `HSL ${this.$t("common.commute_counter")}`,
      refreshOnceOnNavigation: true,
    };
  },
  methods: {
    userLoaded() {
      this.$store.dispatch("companies/getCompanies");
    },
  },
  mounted() {
    const storageLocale = localStorage.getItem("locale");
    if (storageLocale) {
      this.$store.commit("SET_LOCALE", storageLocale);
    }
    if (!storageLocale) {
      this.$store.commit("SET_LOCALE", this.$i18n.locale);
    }
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
  },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    ...mapState({
      selectedCompanyId: (state) => state.companies.selectedCompanyId,
      companies: (state) => state.companies.companies,
    }),
  },
};
</script>

<style lang="scss">
@import "./styles/style.scss";

body {
  margin: 0;
  background-color: white;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-content {
  background-color: #ffffff;
  min-height: 60vh;
}

.header {
  align-items: center;
  background-color: $color-hsl-sininen;
  color: white;
}

.header > p {
  display: inline-block;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
}

.logo {
  height: 1.5rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.menu {
  margin: 0.5em;
  display: inline-block;
  background-color: $color-custom-dark-blue;
  vertical-align: middle;
  border-radius: 0.1em;
}

@include media(">=medium") {
  .header {
    display: flex;
    flex-direction: row;
  }

  .menu {
    margin: 0 1em 0 auto;
  }
}
</style>
