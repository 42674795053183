import * as users from "./modules/users";
import * as inquiries from "./modules/inquiries";
import * as companies from "./modules/companies";
import * as workplaces from "./modules/workplaces";
import * as reports from "./modules/reports";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { i18n } from "@/main";

import hsl from "../api/axiosConfig";

import Vue from "vue";
import Vuex from "vuex";
import { getOidcSettings } from "../config/oidc";

Vue.use(Vuex);

const state = {
  selectedLocale: "",
  isAdmin: false,
};

const mutations = {
  SET_LOCALE(state, locale) {
    state.selectedLocale = locale;
    localStorage.setItem("locale", locale);
    i18n.locale = locale;
  },
  SET_ADMIN(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
};

//export store module
export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      getOidcSettings(),
      {
        isAuthenticatedBy: "access_token",
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded(user) {
          hsl.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${user.access_token}`;
            return config;
          });
        },
      }
    ),
    users,
    workplaces,
    inquiries,
    companies,
    reports,
  },
  state,
  mutations,
});
