import {
  getOrganizations,
  getOrganization,
} from "@/api/organizationController";
import { getLoggedInUserRole } from "@/api/userController";
import { i18n } from "../../main";

export const namespaced = true;

export const state = {
  companies: [],
  selectedCompanyId: null,
  selectedCompany: {},
  isLoading: false,
  error: "",
};

export const actions = {
  async getCompanies({ commit, state, dispatch }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", "");

    try {
      const userRole = await getLoggedInUserRole();
      commit("SET_ADMIN", userRole.hslAdmin, { root: true });

      if (userRole && userRole.hslAdmin) {
        console.log("Fetching all companies");
        const data = await getOrganizations();
        commit("SET_COMPANIES", data);
        const sessionCompanyId = parseInt(sessionStorage.getItem("companyId"));
        const sessionCompanyIdMatchesData = data.some(
          (company) => company.id === sessionCompanyId
        );
        if (
          sessionCompanyId &&
          sessionCompanyIdMatchesData &&
          !state.selectedCompanyId
        ) {
          return dispatch("selectCompanyWithoutFetch", sessionCompanyId);
        }
      }
      if (userRole.organisationId) {
        console.log("Fetching company", userRole.organisationId);
        const data = await getOrganization(userRole.organisationId);
        commit("SET_COMPANIES", [data]);
        return dispatch(
          "selectCompanyWithoutFetch",
          parseInt(userRole.organisationId)
        );
      }
    } catch {
      commit("SET_ERROR", "Unable to get companies");
    } finally {
      commit("SET_LOADING", false);
    }
  },
  selectCompany({ commit, dispatch }, id) {
    commit("SELECT_COMPANY", id);
    dispatch("getCompanies");
    dispatch("inquiries/getInquiriesByCompany", id, { root: true });
    dispatch("workplaces/getWorkplacesByCompanyId", id, { root: true });
    dispatch("reports/getCommuterTypesList", null, { root: true });
    dispatch("users/getUsersByCompany", id, { root: true });
  },
  selectCompanyWithoutFetch({ commit, dispatch }, id) {
    commit("SELECT_COMPANY", id);
    dispatch("inquiries/getInquiriesByCompany", id, { root: true });
    dispatch("workplaces/getWorkplacesByCompanyId", id, { root: true });
    dispatch("reports/getCommuterTypesList", null, { root: true });
    dispatch("users/getUsersByCompany", id, { root: true });
  },
};

export const mutations = {
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SELECT_COMPANY(state, companyId) {
    state.selectedCompanyId = companyId;
    sessionStorage.setItem("companyId", companyId);
    if (!companyId) return (state.selectedCompany = {});
    state.selectedCompany = state.companies.find(
      (company) => company.id === companyId
    );
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.isLoading = error;
  },
};

export const getters = {
  selectFromCompanies: (state) => [
    {
      value: null,
      text: i18n.t("common.select_company"),
    },
    ...state.companies.map((item) => {
      return {
        value: item.id,
        text: item.name,
      };
    }),
  ],
};
