import hsl from "./axiosConfig";

export const getOrganizations = () => {
  const response = hsl
    .get("/organisations/")
    .then((res) => res.data)
    .catch((err) => console.log("Unable to get organisations", err));
  return response;
};

export const getOrganization = (id) => {
  const response = hsl
    .get(`/organisations/${parseInt(id)}`)
    .then((res) => res.data)
    .catch((err) => console.log("Unable to get organization", err));
  return response;
};

export const updateOrganization = (payload) => {
  const response = hsl
    .put(`/organisations/update/${payload.id}/`, {
      ...payload,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(`Unable to update ${payload.id}`, err);
      return err;
    });
  return response;
};

export const createOrganization = (payload) => {
  const response = hsl
    .post(`/organisations/create/`, {
      ...payload,
      id: 0,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(`Unable to create "${payload.name}"`, err);
      return err;
    });
  return response;
};
