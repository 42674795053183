<template>
  <div class="loading text-primary py-5">
    <b-spinner variant="primary" class="mr-2"></b-spinner
    >{{ $t("common.loading") }}
  </div>
</template>
<script>
export default {
  name: "loading",
};
</script>
<style lang="scss" scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  &-container {
    height: 100%;
  }
}
</style>
