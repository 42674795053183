export const oidcSettings = {
  authority: process.env.VUE_APP_HSL_AUTH_URL,
  client_id: process.env.VUE_APP_HSL_CLIENT_ID,
  client_secret: process.env.VUE_APP_HSL_CLIENT_SECRET,
  redirect_uri: process.env.VUE_APP_HSL_REDIRECT_URI,
  responseType: "code",
  scope:
    "openid, email, profile, https://oneportal.trivore.com/scope/groups.readonly",
  prompt: "login",
};

export const getOidcSettings = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const sessionIsAdmin = () => {
    const storageAdmin =
      sessionStorage.getItem("admin-login") === "true" ? true : null;
    if (storageAdmin === null) {
      sessionStorage.setItem("admin-login", urlParams.has("admin-login"));
      return urlParams.has("admin-login");
    }
    return storageAdmin;
  };
  if (!sessionIsAdmin()) {
    return oidcSettings;
  }

  return {
    ...oidcSettings,
    client_id: process.env.VUE_APP_HSL_CLIENT_ID_ADMIN,
    client_secret: process.env.VUE_APP_HSL_CLIENT_SECRET_ADMIN,
  };
};
