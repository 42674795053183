<template>
  <div class="not-found-container">
    <h1>{{ $t("common.not_found_heading") }}</h1>
    <span>{{ $t("common.not_found_text") }}</span>
    <router-link to="/" class="button button--filled-blue">{{
      $t("common.not_found_link")
    }}</router-link>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: this.$t("common.not_found_heading"),
    };
  },
};
</script>
<style lang="scss" scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;

  span {
    margin-bottom: 1.5rem;
  }
}
</style>
