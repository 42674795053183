<template lang="">
  <b-navbar toggleable="lg" type="dark" class="nav">
    <div class="nav-wrapper">
      <b-navbar-brand
        to="/"
        style="
          font-size: 0.9em;
          text-transform: uppercase;
          color: #ffffff;
          font-weight: bold;
        "
      >
        <img
          class="logo"
          src="@/assets/hsl_vaaka_valkoinen.svg"
          alt="HSL logo"
        />
        {{ $t("common.commute_counter") }}
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </div>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          v-for="item in navlinks"
          :key="item.text"
          :to="item.link === '/' ? '/' : `/${$t(`url.${item.translate}`)}`"
        >
          {{ $t(`navigation.${item.text}`) }}
        </b-nav-item>
        <b-nav-item v-if="oidcIsAuthenticated" @click="signOutOidc">
          {{ $t("common.sign_out") }}
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <language-picker variant="primary-dark" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import LanguagePicker from "@/components/LanguagePicker";
import { mapGetters, mapActions } from "vuex";

const navlinks = [
  {
    link: "/",
    text: "results",
  },
  {
    link: "/company-info",
    text: "company_info",
    translate: "company-info",
  },
  {
    link: "/inquiries",
    text: "inquiries",
    translate: "inquiries",
  },
];

export default {
  name: "navigation",
  components: { LanguagePicker },
  data() {
    return {
      navlinks: navlinks,
    };
  },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
  },
  methods: {
    ...mapActions(["signOutOidc"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/style.scss";
.nav {
  background-color: $color-hsl-sininen;
}
.navbar-dark .navbar-toggler {
  border: none !important;
  color: #fff !important;

  &:focus {
    outline: none;
  }
}
.nav-link {
  &.router-link-exact-active {
    border-bottom: 4px solid #fff;
  }
}

.navbar {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  align-items: stretch;
  column-gap: 0.5rem;
}

.nav-wrapper {
  height: 70px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: fit-content;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }
}
.navbar-nav {
  display: flex;
  @include media-breakpoint-up(lg) {
    height: 100%;
    .nav-item {
      a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
// .navbar-nav {
//   min-height: 100%;
//   .nav-item {
//     min-height: 100%;
//     a.nav-link {
//       display: flex;
//       flex-direction: column;
//       flex: 1;
//       justify-content: center;
//       min-height: 100%;
//     }
//   }
// }
</style>
