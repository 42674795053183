import { getCommuterTypes } from "@/api/reportController";
import { i18n } from "../../main";

export const namespaced = true;

export const state = {
  commuterTypes: [],
  selectedCommuterType: "",
  isLoading: false,
  error: "",
};

export const actions = {
  async getCommuterTypesList({ state, commit }) {
    commit("SET_LOADING", true);
    commit("SET_COMMUTER_TYPES", []);
    commit("SET_ERROR", []);

    try {
      const response = await getCommuterTypes(state.selectedLocale);
      commit("SET_COMMUTER_TYPES", response);
    } catch {
      commit("SET_ERROR", "Error getting commuter types");
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const mutations = {
  SET_COMMUTER_TYPES(state, type) {
    state.commuterTypes = type;
  },
  SELECT_COMMUTER_TYPE(state, type) {
    state.selectedCommuterType = type;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const getters = {
  commuterTypesDropdown: (state) => [
    {
      value: null,
      text: i18n.t("common.select_profile"),
    },
    ...state.commuterTypes.map((item) => ({
      value: item.name,
      text: item.value,
    })),
  ],
};
