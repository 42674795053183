<template>
  <b-dropdown
    v-if="languages.length > 1"
    id="dropdown-right"
    right
    :text="$i18n.locale.toUpperCase()"
    variant="primary"
    class="m-0"
  >
    <b-dropdown-item
      v-for="option in languages"
      :key="option"
      @click.stop="selectLocale(option)"
      href="#"
    >
      {{ option.toUpperCase() }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "language-picker",
  props: {
    size: String,
    variant: String,
  },
  methods: {
    selectLocale(option) {
      this.$i18n.locale = option;
      this.$store.commit("SET_LOCALE", option);
    },
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    languages() {
      return Object.keys(this.$i18n.messages);
    },
  },
};
</script>

<style scoped lang="scss">
.language-picker {
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-around;
  margin: 0.1em;
}
</style>
