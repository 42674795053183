import { getUsersByCompanyId, sendInvite } from "@/api/userController";

export const namespaced = true;

export const state = {
  users: [],
  isLoading: false,
  error: "",
};

export const actions = {
  async getUsersByCompany({ rootState, commit }, id) {
    commit("SET_LOADING", true);
    commit("SET_USERS", []);
    commit("SET_ERROR", []);
    try {
      const response = await getUsersByCompanyId(id, rootState.selectedLocale);
      commit("SET_USERS", response);
    } catch {
      commit("SET_ERROR", "Getting users failed");
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async sendInviteEmails({ rootState, dispatch }, payload) {
    const response = await sendInvite(
      rootState.companies.selectedCompanyId,
      rootState.selectedLocale,
      payload
    );
    dispatch("getUsersByCompany", rootState.companies.selectedCompanyId);
    return response;
  },
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};
