import hsl from "./axiosConfig";

export const getLoggedInUserRole = () => {
  const response = hsl
    .get("/users/role/")
    .then((res) => res.data)
    .catch((err) => console.log("Could not load user roles", err));
  return response;
};

export const getUsersByCompanyId = (companyId, locale = "fi") => {
  if (!companyId || !locale) return;

  const response = hsl
    .get(`/users/by-organisation/${companyId}/${locale}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error("Unable to get users by company", err);
    });

  return response;
};

export const sendInvite = (companyId, locale, payload) => {
  if (!companyId || !locale) return;

  const response = hsl
    .post(`/users/create-invitations/${companyId}/${locale}/`, payload)
    .then((res) => res)
    .catch(() => "Could not send invitation(s)");

  return response;
};

export const removeInvitations = async (
  companyId,
  locale = "fi",
  invitationHashes
) => {
  if (!companyId || !locale || !invitationHashes) return;
  try {
    const response = await hsl.post(
      `/users/remove-invitations/${companyId}/${locale}`,
      invitationHashes
    );
    return response;
  } catch {
    return "Could not remove invite(s)";
  }
};

export const createUser = async (payload) => {
  try {
    const response = await hsl.post("/users/create/", payload);
    return response;
  } catch {
    throw new Error("Couldn't register user");
  }
};

export const checkIfUserExists = async (email) => {
  try {
    const response = await hsl.get(`/users/exists/${email}`);
    return response.data;
  } catch {
    throw new Error("Couldn't validate user email");
  }
};
