import Vue from "vue";
import VueRouter from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store";
import Callback from "../views/Callback.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/callback", // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: "Callback",
    component: Callback,
  },
  {
    path: "/register/:hash/:email",
    name: "Register",
    alias: "/rekisteroidy",
    component: () => import("../views/Register.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    // NEEDS SOME FUNCTIONALITY
    path: "/invitation/:locale/:hash/:email",
    component: () => import("../views/Invitation.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/company-info",
    name: "Company Info",
    alias: "/yrityksen-tiedot",
    component: () => import("../views/CompanyInfo.vue"),
  },
  {
    path: "/inquiries",
    name: "inquiries",
    alias: "/kyselyt",
    component: () => import("../views/Inquiries.vue"),
  },
  {
    path: "/",
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "summary",
        component: () => import("../components/dashboard/Summary.vue"),
      },
      {
        path: "background-info",
        name: "background-info",
        alias: "taustatiedot",
        component: () => import("../components/dashboard/BackgroundInfo.vue"),
      },
      {
        path: "commuting",
        name: "commuting",
        alias: "tyomatkat",
        component: () => import("../components/dashboard/Commuting.vue"),
      },
      {
        path: "safety",
        name: "safety",
        alias: "turvallisuus",
        component: () => import("../components/dashboard/Safety.vue"),
      },
      {
        path: "meeting-trips",
        name: "meeting-trips",
        alias: "asiointimatkat",
        component: () => import("../components/dashboard/MeetingTrips.vue"),
      },
      {
        path: "exercise",
        name: "exercise",
        alias: "liikunta",
        component: () => import("../components/dashboard/Exercise.vue"),
      },
      {
        path: "change-potential",
        name: "change-potential",
        alias: "muutospotentiaali",
        component: () => import("../components/dashboard/ChangePotential.vue"),
      },
      {
        path: "feedbacks",
        name: "feedbacks",
        alias: "palautteet",
        component: () => import("../components/dashboard/Feedbacks.vue"),
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store));

export default router;
