import { getWorkplaces, createWorkplace } from "@/api/workplaceController";
import { i18n } from "../../main";

export const namespaced = true;

export const state = {
  workplaces: [],
  isLoading: false,
  error: "",
  selectedWorkplace: null,
};

export const actions = {
  async getWorkplacesByCompanyId({ commit }, companyId) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", "");
    commit("SET_WORKPLACES", []);
    try {
      const data = await getWorkplaces(companyId);
      commit("SET_WORKPLACES", data);
    } catch {
      commit("SET_ERROR", `Getting workplaces failed (ID: ${companyId})`);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async createWorkplaceToSelectedCompany({ dispatch, rootState }, data) {
    const response = await createWorkplace({
      ...data,
      organisationId: rootState.companies.selectedCompanyId,
      id: 0,
    });
    dispatch("getWorkplacesByCompanyId", rootState.companies.selectedCompanyId);
    return response;
  },
  selectWorkplace({ commit }, id) {
    commit("SELECT_WORKPLACE", id);
  },
};

export const mutations = {
  SET_WORKPLACES(state, workplaces) {
    state.workplaces = workplaces;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SELECT_WORKPLACE(state, id) {
    state.selectedWorkplace = id;
  },
};

export const getters = {
  workplacesDropdown: (state) => [
    {
      value: null,
      text: i18n.t("common.select_workplace"),
    },
    ...state.workplaces.map((item) => ({
      value: item.id,
      text: item.name,
    })),
  ],
};
