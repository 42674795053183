import hsl from "./axiosConfig";

export const createWorkplace = (payload) => {
  const response = hsl
    .post("/createWorkplace", payload)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error("Unable to create workplace", err);
    });
  return response;
};

export const getWorkplaces = (companyId) => {
  if (!companyId) {
    console.log("No company ID set");
    return;
  }
  const response = hsl
    .get(`/getWorkplaces/${companyId}/`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error("Unable to get workplace", err);
    });
  return response;
};

export const updateWorkplace = (workplaceId) => {
  if (!workplaceId) return;

  const response = hsl
    .put(`/updateWorkplace/${workplaceId}/`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(`Unable to update workplace ${workplaceId}`, err);
    });

  return response;
};
