import {
  getInquiriesByCompanyId,
  createInquiry,
} from "@/api/inquiryController";
import { isAfter, isBefore, isToday, parseISO } from "date-fns";

export const namespaced = true;

export const state = {
  inquiries: [],
  selectedInquiryId: null,
  selectedInquiry: {},
  isLoading: false,
  error: "",
};

export const actions = {
  async createInquiryToCompany({ rootState, dispatch }, payload) {
    try {
      const response = await createInquiry(payload);
      dispatch(
        "getInquiriesByCompany",
        rootState.companies.selectedCompanyId,
        rootState.selectedLocale
      );
      return response;
    } catch {
      return "Error creating inquiry";
    }
  },
  async getInquiriesByCompany({ commit, rootState }, id) {
    commit("SET_LOADING", true);
    commit("SET_INQUIRIES", []);
    commit("SELECT_INQUIRY", null);
    commit("SET_ERROR", "");
    try {
      const response = await getInquiriesByCompanyId(
        id,
        rootState.selectedLocale
      );
      commit("SET_INQUIRIES", response);
    } catch {
      commit("SET_ERROR", `Error getting inquiries for company id: ${id}`);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async selectInquiry({ commit }, id) {
    commit("SELECT_INQUIRY", id);
  },
};

export const mutations = {
  SET_INQUIRIES(state, inquiries) {
    state.inquiries = inquiries;
    if (!state.selectedInquiryId && inquiries && inquiries.length > 0) {
      state.selectedInquiryId = state.inquiries[0].queryId;
      state.selectedInquiry = state.inquiries[0];
    }
  },
  SELECT_INQUIRY(state, inquiryId) {
    if (!inquiryId) {
      state.selectedInquiryId = null;
      state.selectedInquiry = {};
      return;
    }
    state.selectedInquiryId = inquiryId;
    state.selectedInquiry = state.inquiries.find(
      (inquiry) => inquiry.queryId === inquiryId
    );
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const getters = {
  userInquiries: (state) => state.inquiries,
  inquiriesDropdown: (state) =>
    state.inquiries.map((item) => {
      return {
        value: item.queryId,
        text: item.queryName,
      };
    }),
  pastInquiries: (state) => {
    const today = new Date();
    return state.inquiries
      .filter((item) => {
        const inquiryDate = parseISO(item.queryEndDate);
        if (isAfter(today, inquiryDate)) {
          return item;
        }
      })
      .sort((a, b) => parseISO(a.queryStartDate) - parseISO(b.queryStartDate));
  },
  currentAndUpcomingInquiries: (state) => {
    const today = new Date();
    return state.inquiries
      .filter((item) => {
        const inquiryEndDate = parseISO(item.queryEndDate);
        if (isToday(inquiryEndDate) || isBefore(today, inquiryEndDate)) {
          return item;
        }
      })
      .sort((a, b) => parseISO(a.queryStartDate) - parseISO(b.queryStartDate));
  },
};
