import hsl from "./axiosConfig";
import { app, i18n } from "@/main";

export const createInquiry = async (payload) => {
  try {
    const response = await hsl.post("/createQuery", payload);
    return response;
  } catch {
    throw new Error("Unable to create query");
  }
};

export const getInquiriesByCompanyId = async (companyId, locale = "fi") => {
  if (!companyId || !locale) return;
  try {
    const response = await hsl.get(
      `/queries/by-organisation/${companyId}/${locale}/`
    );
    return response.data;
  } catch (err) {
    return `Unable to get inquiries from company: ${companyId} (${locale})`;
  }
};

export const updateQueryById = async (queryId, locale = "fi", payload) => {
  if (!queryId || !locale) return;
  try {
    const response = await hsl.put(
      `/queries/update/${queryId}/${locale}`,
      payload
    );
    app.$bvToast.toast(i18n.t("toast.updated_successfully"), {
      toaster: "b-toaster-bottom-right",
      variant: "success",
      noCloseButton: true,
    });
    return response;
  } catch {
    app.$bvToast.toast(i18n.t("toast.something_went_wrong"), {
      toaster: "b-toaster-bottom-right",
      variant: "danger",
      noCloseButton: true,
    });
    throw new Error("Unable to modify inquiry");
  }
};

export const getQueryByHash = async (hash, locale = "fi") => {
  if (!hash || !locale) return;
  try {
    const response = await hsl.get(`/queries/by-hash/${hash}/${locale}`);
    return response.data;
  } catch {
    throw new Error("Unable to get query by hash");
  }
};

export const deleteQueryById = async (queryId, locale = "fi") => {
  if (!queryId || !locale) return;
  try {
    const response = await hsl.delete(`/queries/delete/${queryId}/${locale}`);
    return response;
  } catch {
    return `Unable to delete query: ${queryId}`;
  }
};
